var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    {
      staticClass: "d-flex justify-center align-center",
      staticStyle: { height: "100vh" },
      attrs: { id: "acesso-negado" }
    },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-card-text",
                    { staticClass: "text-center" },
                    [
                      _c("h1", [_vm._v("ACESSO NEGADO")]),
                      _c("h4", [
                        _vm._v(
                          "Desculpe, você não tem permissão para acessar esta página."
                        )
                      ]),
                      _c(
                        "v-btn",
                        {
                          staticClass: "mt-5",
                          attrs: { color: "primary", to: "/" }
                        },
                        [_vm._v("Voltar ao início")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }